<template>
  <div>
    <a-card>
      <!--            <div class="table-operator">-->
      <!--                <a-button type="primary" icon="plus" @click="addCity">新建</a-button>-->
      <!--            </div>-->
      <div class="table-operator">
        <a-form layout="inline">
          <a-row :gutter="0">
            <a-col :md="7" :sm="24">
              <a-form-item label="APP名称">
                <a-select
                  style="width: 175px"
                  v-model="queryParam.appCode"
                  @change="selectList()"
                  allowClear
                  placeholder="请选择"
                >
                  <a-select-option v-for="item in appStyle" :key="item.id">
                    {{ item.name }}
                  </a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
            <a-col :md="7" :sm="24">
              <a-form-item label="操作系统">
                <a-select
                  style="width: 175px"
                  v-model="queryParam.os"
                  @change="selectList()"
                  allowClear
                  placeholder="请选择"
                >
                  <a-select-option value="Android">Android</a-select-option>
                  <a-select-option value="IOS">IOS</a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
            <a-col :md="7" :sm="24">
              <a-form-item label="更新方式">
                <a-select
                  style="width: 175px"
                  v-model="queryParam.updateMode"
                  @change="selectList()"
                  allowClear
                  placeholder="请选择"
                >
                  <a-select-option v-for="item in updateStyle" :key="item.id">
                    {{ item.name }}
                  </a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
            <a-col :md="24" :sm="24" style="text-align: right">
              <span class="table-page-search-submitButtons">
                <a-button
                  style="margin-left: 8px"
                  @click="() => ((queryParam = {}), this.init())"
                  >重置</a-button
                >
              </span>
            </a-col>
          </a-row>
        </a-form>
      </div>
      <div class="table-operator">
        <a-button type="primary" icon="plus" @click="addVension">新建</a-button>
      </div>
      <a-table
        rowKey="id"
        :pagination="pagination"
        :columns="columns"
        :dataSource="data"
        :scroll="{ x: 800 }"
        @change="changePageSize"
      >
        <span slot="serial" slot-scope="text, record, index">
          {{ index + 1 }}
        </span>
        <span slot="action" slot-scope="text, record" class="blueColor">
          <template>
            <a @click="editVension(record)">编辑</a>&nbsp;&nbsp;
            <a @click="deleVension(record)">删除</a>
          </template>
        </span>
      </a-table>
      <a-modal
        title="新增版本"
        :width="640"
        :visible="viewnew"
        :confirmLoading="confirmLoading"
        destroyOnClose
        @ok="handleaddVension"
        @cancel="handleCancel"
      >
        <a-spin :spinning="confirmLoading">
          <a-form :form="form">
            <a-form-item
              label="APP名称"
              :labelCol="labelCol"
              :wrapperCol="wrapperCol"
            >
              <a-select
                v-decorator="[
                  'appCode',
                  { rules: [{ required: true, message: '请选择' }] }
                ]"
              >
                <a-select-option v-for="item in appStyle" :key="item.id">
                  {{ item.name }}
                </a-select-option>
              </a-select>
            </a-form-item>
            <a-form-item
              label="操作系统"
              :labelCol="labelCol"
              :wrapperCol="wrapperCol"
            >
              <a-select
                v-decorator="[
                  'os',
                  { rules: [{ required: true, message: '请选择' }] }
                ]"
              >
                <a-select-option value="Android">Android</a-select-option>
                <a-select-option value="IOS">IOS</a-select-option>
              </a-select>
            </a-form-item>
            <a-form-item
              label="版本号"
              :labelCol="labelCol"
              :wrapperCol="wrapperCol"
            >
              <a-input
                v-decorator="[
                  'version',
                  { rules: [{ required: true, message: '请输入' }] }
                ]"
              />
            </a-form-item>
            <a-form-item
              label="更新方式"
              :labelCol="labelCol"
              :wrapperCol="wrapperCol"
            >
              <a-select
                v-decorator="[
                  'updateType',
                  { rules: [{ required: true, message: '请选择' }] }
                ]"
              >
                <a-select-option v-for="item in updateStyle" :key="item.id">
                  {{ item.name }}
                </a-select-option>
              </a-select>
            </a-form-item>
            <a-form-item
              label="下载地址"
              :labelCol="labelCol"
              :wrapperCol="wrapperCol"
            >
              <a-input
                v-decorator="[
                  'url',
                  { rules: [{ required: true, message: '请输入' }] }
                ]"
              />
            </a-form-item>
            <a-form-item
              class="backName"
              label="更新描述"
              :labelCol="labelCol"
              :wrapperCol="wrapperCol"
            >
              <a-textarea
                v-decorator="[
                  'remark',
                  { rules: [{ required: true, message: '请输入' }] }
                ]"
              />
            </a-form-item>
          </a-form>
        </a-spin>
      </a-modal>
      <a-modal
        title="编辑"
        :width="640"
        :visible="edit"
        :confirmLoading="confirmLoading"
        destroyOnClose
        @ok="handleeditVension"
        @cancel="handleCancel"
      >
        <a-spin :spinning="confirmLoading">
          <a-form :form="form">
            <a-form-item
              label="APP名称"
              :labelCol="labelCol"
              :wrapperCol="wrapperCol"
            >
              <a-select
                v-decorator="[
                  'appCode',
                  {
                    rules: [{ required: true, message: '请选择' }],
                    initialValue: this.thisDetail.appCode
                  }
                ]"
              >
                <a-select-option v-for="item in appStyle" :key="item.id">
                  {{ item.name }}
                </a-select-option>
              </a-select>
            </a-form-item>
            <a-form-item
              label="操作系统"
              :labelCol="labelCol"
              :wrapperCol="wrapperCol"
            >
              <a-select
                v-decorator="[
                  'os',
                  {
                    rules: [{ required: true, message: '请选择' }],
                    initialValue: this.thisDetail.os
                  }
                ]"
              >
                <a-select-option value="Android">Android</a-select-option>
                <a-select-option value="IOS">IOS</a-select-option>
              </a-select>
            </a-form-item>
            <a-form-item
              label="版本号"
              :labelCol="labelCol"
              :wrapperCol="wrapperCol"
            >
              <a-input
                v-decorator="[
                  'version',
                  {
                    rules: [{ required: true, message: '请输入' }],
                    initialValue: this.thisDetail.version
                  }
                ]"
              />
            </a-form-item>
            <a-form-item
              label="更新方式"
              :labelCol="labelCol"
              :wrapperCol="wrapperCol"
            >
              <a-select
                v-decorator="[
                  'updateType',
                  {
                    rules: [{ required: true, message: '请选择' }],
                    initialValue: this.updateModeedit
                  }
                ]"
              >
                <a-select-option v-for="item in updateStyle" :key="item.id">
                  {{ item.name }}
                </a-select-option>
              </a-select>
            </a-form-item>
            <a-form-item
              label="下载地址"
              :labelCol="labelCol"
              :wrapperCol="wrapperCol"
            >
              <a-input
                v-decorator="[
                  'url',
                  {
                    rules: [{ required: true, message: '请输入' }],
                    initialValue: this.thisDetail.url
                  }
                ]"
              />
            </a-form-item>
            <a-form-item
              class="backName"
              label="更新描述"
              :labelCol="labelCol"
              :wrapperCol="wrapperCol"
            >
              <a-textarea
                v-decorator="[
                  'remark',
                  {
                    rules: [{ required: true, message: '请输入' }],
                    initialValue: this.thisDetail.remark
                  }
                ]"
              />
            </a-form-item>
          </a-form>
        </a-spin>
      </a-modal>
    </a-card>
  </div>
</template>

<script>
  
import axios from "axios";
import moment from "moment";
import Bus from "@/bus";
import { defaultPagination } from "@/utils/pagination";
export default {
  name: "appVersion",
  data() {
    return {
      data: [],
      viewnew: false,
      edit: false,
      visible: false,
      confirmLoading: false,
      labelCol: {
        xs: { span: 24 },
        sm: { span: 7 }
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 13 }
      },
      updateStyle: [
        { id: 1, name: "强制更新" },
        { id: 2, name: "提示更新" },
        { id: 3, name: "检测更新" }
      ],
      appStyle: [
        { id: "chef", name: "云厨管家" },
        { id: "merchant", name: "星厨闪电" },
        { id: "otter", name: "Otter" },
      ],
      form: this.$form.createForm(this),
      thisDetail: "",
      updateModeedit: "",
      queryParam: {
        appCode: "",
        os: "",
        updateMode: ""
      },
      pagination: defaultPagination(() => {}),
      columns: [
        {
          title: "APP名称",
          dataIndex: "appName",
          key: "appName",
          fixed: "left"
        },
        {
          title: "操作系统",
          dataIndex: "os",
          key: "os"
        },
        {
          title: "版本号",
          dataIndex: "version",
          key: "version"
        },
        {
          title: "更新方式",
          dataIndex: "updateMode",
          key: "updateMode"
        },
        {
          title: "更新描述",
          dataIndex: "remark",
          key: "remark"
        },
        {
          title: "下载地址",
          dataIndex: "url",
          key: "url"
        },
        {
          title: "创建人",
          dataIndex: "creator",
          key: "creator"
        },
        {
          title: "更新时间",
          dataIndex: "updateTimeLg",
          key: "updateTimeLg"
        },
        {
          title: "操作",
          dataIndex: "action",
          scopedSlots: { customRender: "action" },
          key: "action",
          width: 140,
          fixed: "right"
        }
      ],
      cityConfigureEdit: false
    };
  },
  created() {
    this.init();
    Bus.$on("getTarget", target => {
      this.init();
    });
  },
  methods: {
    moment,
    changePageSize(val) {
      this.init(val.current, val.pageSize);
    },
    init(current, pageSize) {
      axios({
        url:   this.$baseUrl + "appVersion/listWithPage",
        withCredentials: true,
        method: "GET",
        params: {
          rows: pageSize || 20,
          page: current || 1,
          ...this.queryParam
        }
      }).then(res => {
        this.data = res.data.rows;
        if (res.data.rows) {
          res.data.rows.forEach(item => {
            item.updateMode = this.changeupdateStyle(item.updateMode);
            // item.os = this.changeos(item.os);
            this.$set(
              item,
              "updateTimeLg",
              item.updateTimeLg
                ? moment(item.updateTimeLg).format("YYYY-MM-DD")
                : ""
            );
          });
          this.data = res.data.rows;
          const pagination = { ...this.pagination };
          pagination.total = res.data.count;
          this.pagination = pagination;
        }
      });
    },
    changeupdateStyle(val) {
      let name = "";
      if (val) {
        this.updateStyle.forEach(item => {
          if (val == item.id) {
            name = item.name;
          }
        });
      }
      return name;
    },
    changeos(val) {
      let name = "";
      if (val) {
        this.appStyle.forEach(item => {
          if (val == item.id) {
            name = item.name;
          }
        });
      }
      return name;
    },
    addVension() {
      this.viewnew = true;
    },
    editVension(val) {
      // console.log(val);
      // changeupdateStyle(val) {
      let code = "";
      if (val.updateMode) {
        this.updateStyle.forEach(item => {
          if (val.updateMode == item.name) {
            code = item.id;
          }
        });
      }
      this.updateModeedit = code;
      // return name;
      // },
      this.thisDetail = val;
      this.edit = true;
    },
    deleVension(val) {
      axios({
        url:   this.$baseUrl + "appVersion/remove",
        method: "GET",
        params: {
          id: val.id
        }
      }).then(res => {
        if (res.data.success) {
          this.$message.success("删除成功！");
        } else {
          this.$message.error(`${res.data.returnMsg}`);
        }
        this.init();
      });
    },
    handleaddVension() {
      const {
        form: { validateFields }
      } = this;
      this.confirmLoading = true;
      validateFields((errors, values) => {
        if (!errors) {
          this.vensionAdd(values);
          setTimeout(() => {
            this.viewnew = false;
            this.confirmLoading = false;
            this.$emit("ok", values);
          }, 1500);
        } else {
          this.viewnew = false;
          this.confirmLoading = false;
        }
      });
    },
    handleeditVension() {
      const {
        form: { validateFields }
      } = this;
      this.confirmLoading = true;
      validateFields((errors, values) => {
        if (!errors) {
          this.vensionEdit(values);
          setTimeout(() => {
            this.edit = false;
            this.confirmLoading = false;
            this.$emit("ok", values);
          }, 1500);
        } else {
          this.edit = false;
          this.confirmLoading = false;
        }
      });
    },
    vensionAdd(val) {
      axios({
        url:   this.$baseUrl + "appVersion/save",
        method: "POST",
        data: {
          ...val
        }
      }).then(res => {
        if (res.data.success) {
          this.$message.success("保存成功！");
        } else {
          this.$message.error(`${res.data.returnMsg}`);
        }
        this.init();
      });
    },
    vensionEdit(val) {
      axios({
        url:   this.$baseUrl + "appVersion/modify",
        method: "POST",
        data: {
          ...val,
          id: this.thisDetail.id
        }
      }).then(res => {
        if (res.data.success) {
          this.$message.success("保存成功！");
        } else {
          this.$message.error(`${res.data.returnMsg}`);
        }
        this.init();
      });
    },
    // 搜索
    selectList() {
      this.init();
    },
    handleCancel() {
      this.viewnew = false;
      this.edit = false;
    }
  }
};
</script>

<style scoped>
.table-operator {
  margin-bottom: 24px;
}
.backName .ant-input {
  border-radius: 0 !important;
  width: 445px;
  height: 200px;
}
</style>
